<template>
  <div class="grid mt-1">
    <div id="shipment_form" class="card p-fluid p-inputtext-sm md:col-6">
      <h5 style="font-weight:bold;">{{ $t('shipment.form_create')}}</h5>
      <div class="field grid">
        <label for="agentCode" class="col-12 mb-2 md:col-3 md:mb-0">{{$t('shipment.agentCode')}}</label>
        <form-field :message="errors.agentCode" class="col-12 md:col-9">
          <Dropdown id="agentCode" 
              v-model="entity.agentCode" 
              :options="partners" 
              optionLabel="label" 
              optionValue="name" 
              @change="onChangeAgentCode"
              :placeholder="$t('shipment.selectPartner')" />
        </form-field>
      </div>
      <div class="field grid">
        <label for="shipmentCode" class="col-12 mb-2 md:col-3 md:mb-0">{{$t('shipment.code')}}</label>
        <form-field :message="errors.shipmentCode" class="col-12 md:col-9">
          <InputText ref="shipmentCode" id="shipmentCode" v-model.trim="entity.shipmentCode"  disabled/>
        </form-field>
      </div>
      <div class="field grid">
        <label for="referenceCode" class="col-12 mb-2 md:col-3 md:mb-0">{{$t('shipment.referenceCode')}}</label>
        <form-field :message="errors.referenceCode" class="col-12 md:col-9">
          <InputText ref="referenceCode" id="referenceCode" v-model.trim="entity.referenceCode"  autofocus :required="false" style="text-transform: uppercase;"/>
        </form-field>
      </div>
      <div class="field grid">
        <label for="awbCode" class="col-12 mb-2 md:col-3 md:mb-0">{{$t('shipment.awbCode')}}</label>
        <form-field :message="errors.awbCode" class="col-12 md:col-9">
          <InputMask id="awbCode" ref="awbCode" v-model="entity.awbCode" mask="999-99999999" placeholder="xxx-xxxxxxxx" />
          <!-- <InputText ref="awbCode" id="awbCode" v-model.trim="entity.awbCode"  autofocus :required="false"/> -->
        </form-field>
      </div>
      <div class="field grid">
        <label for="weightAwbKg" class="col-12 mb-2 md:col-3 md:mb-0">{{$t('shipment.weightAwbKg')}}</label>
        <form-field :message="errors.weightAwbKg" class="col-12 md:col-9">
          <!-- <InputMask id="weightAwbKg" ref="weightAwbKg" v-model="entity.weightAwbKg" mask="9999" placeholder="xxxx" /> -->
          <InputNumber ref="weightAwbKg" id="weightAwbKg" v-model="entity.weightAwbKg" 
            :min="0" :max="9999" fluid 
            :required="!!entity.awbCode" />
        </form-field>
      </div>
       <div class="field grid">
        <label for="packageNumber" class="col-12 mb-2 md:col-3 md:mb-0">{{$t('shipment.packageNumber')}}</label>
        <form-field :message="errors.packageNumber" class="col-12 md:col-9">
          <InputNumber ref="packageNumber" id="packageNumber" v-model="entity.packageNumber"
            :max="999" :min="0" :required="!entity.awbCode" />
        </form-field>
      </div>
       <div class="field grid">
        <label for="packageType" class="col-12 mb-2 md:col-3 md:mb-0">{{$t('shipment.packageType')}}</label>
        <form-field :message="errors.packageType" class="col-12 md:col-9">
          <Dropdown id="packageType" 
              v-model="entity.packageType" 
              :options="packageTypes"
              :placeholder="$t('shipment.selectPackageType')" />
        </form-field>
      </div>
      <div class="field grid">
        <label for="customGate" class="col-12 mb-2 md:col-3 md:mb-0">{{$t('shipment.customGate')}}</label>
        <form-field :message="errors.customGate" class="col-12 md:col-9">
          <Dropdown id="customGate" 
              v-model="entity.customGate" 
              :options="customGates" 
              optionLabel="label" 
              optionValue="name" 
              :placeholder="$t('shipment.selectCustomGate')" />
        </form-field>
      </div>
      <div class="field grid">
        <label for="departure" class="col-12 mb-2 md:col-3 md:mb-0">{{$t('shipment.departure')}}</label>
        <FormField :message="errors.departure" class="col-12 md:col-9">
          <Calendar v-model="entity.departure" dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" :required="true"/>
        </FormField>
      </div>
      <div class="field grid">
        <label for="arrival" class="col-12 mb-2 md:col-3 md:mb-0">{{$t('shipment.arrival')}}</label>
        <FormField :message="errors.arrival" class="col-12 md:col-9">
          <Calendar v-model="entity.arrival" dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" :required="true"/>
        </FormField>
      </div>
      <div class="field grid">
        <label for="status" class="col-12 mb-2 md:col-3 md:mb-0">{{$t('shipment.status')}}</label>
        <form-field :message="errors.status" class="col-12 md:col-9">
          <Dropdown id="status" 
              v-model="entity.status" 
              :options="shipmentStatuses"
              optionLabel="label" 
              optionValue="value" 
              :placeholder="$t('shipment.selectStatus')" />
        </form-field>
      </div>
      <div class="field grid">
        <label for="note" class="col-12 mb-2 md:col-3 md:mb-0">{{$t('shipment.note')}}</label>
        <div class="col-12 md:col-9">
          <Textarea id="note" v-model="entity.note" required="false" rows="5" cols="20" />
        </div>
      </div>
      <div class="field grid">
        <div class="col-3"/>
        <Toolbar class="col-9 my-2" style="border:0px; padding:0rem 0rem 0rem 0.5rem">
          <template #start>
            <Button :label="$t('button.save')" icon="pi pi-save" @click="onSave($event)"/>
            <i class="mr-2" />
            <!-- <Button :label="$t('button.reset')" icon="pi pi-replay" @click="onReset($event)"/>
            <i class="mr-2" /> -->
          </template>
        </Toolbar>
      </div>  
   </div> 
  </div>
</template>
<script>
import FormField from '@/components/form/FormField';
// import AutoCompleteValue from '@/components/form/AutoCompleteValue';
import PartnerService from "@/service/PartnerService";
import ShipmentService from "@/service/ShipmentService";
import PackageService from "@/service/PackageService";
import formatter from "@/utils/formatter";
import adjustment from "@/utils/adjustment";
import { converter } from "@/utils/common";
import { Consts, ShipmentStatuses } from '@/utils/consts';
import { writeFile, utils } from "xlsx";
import mixin_filter from '@/mixins/filter'
import mixin_form from '@/mixins/form';
import {serializeOrders, serializeFilters } from '@/utils/params';
export default {
  components: { FormField },
  mixins: [mixin_filter, mixin_form],
  data() {
    const customGates = ["TCS", "EMS", "Ngoại quan", "Hà Nội", "Đà Nẵng"]
    return {
      submitted: false,
      showProgressSpinner: false,
      packageCodes: [],
      packageTypes: ["Kiện", "Pallet"],
      receiptCodes: [],
      shipmentCodes: [],
      shipmentStatuses: ShipmentStatuses,
      partners: [],
      customGates: customGates.map(gate => ({
        name: gate,
        label: gate
      })),
      entity: {},
      errors: {},
    }
  },
  computed: {
    totalSourceWeight() {
      return this.allSourcePackages.reduce((total, item) => total + item.weightLbs, 0);
    },
    selectedSourceWeight() {
      return this.selectedSourcePackages.reduce((total, item) => total + item.weightLbs, 0);
    },
    totalTargetWeight() {
      return this.allTargetPackages.reduce((total, item) => total + item.weightLbs, 0);
    },
    selectedTargetWeight() {
      return this.selectedTargetPackages.reduce((total, item) => total + item.weightLbs, 0);
    },
    shippingInfo() {
      let info = "";
      if (this.shipment?.shipmentCode) {
        info += this.shipment.shipmentCode;
        if (this.shipment?.awbCode) {
          info += `- ${this.shipment.awbCode}`;
        }
        if (this.shipment?.departure) {
          let departure = this.formatDate(this.shipment.departure);
          info += `- ${departure}`;
        }
      }

      return info;
    },
    sourceReceipts() {
      let receipts = [];
      if (Array.isArray(this.allSourcePackages)) {
        for (let i = 0; i < this.allSourcePackages.length; i++) {
          let receiptCode = this.allSourcePackages[i].receiptCode;
          if (receipts.indexOf(receiptCode) < 0) {
            receipts.push(receiptCode);
          }
        }
      }
      return receipts;
    },
    targetReceipts() {
      let receipts = [];
      if (Array.isArray(this.allTargetPackages)) {
        for (let i = 0; i < this.allTargetPackages.length; i++) {
          let receiptCode = this.allTargetPackages[i].receiptCode;
          if (receipts.indexOf(receiptCode) < 0) {
            receipts.push(receiptCode);
          }
        }
      }
      return receipts;
    },
  },
  mounted() {
    PartnerService.getAll().then(res => {
      this.partners = res.filter(item => item.partnerType == 'agent').map(partner => ({
        name: partner.code,
        label: partner.code
      }));
    });
    this.$watch(
      () => this.$route.params,
      (params) => {
        this.loadShipment(params.id);
      }
    );
    this.loadShipment(this.$route.params.id);
  },
  methods: {
    loadShipment(id) {
      if (id) {
        ShipmentService.get(id).then((response) => {
          this.entity = adjustment.adjustShipment(response);
          this._calculateWeights();
        });
      }
    },
    loadPackages() {
      let targetUnshippedPkgs = this.allTargetPackages.filter(item => item.shipmentCode == null);
      this.sourceFilterOptions.offset = targetUnshippedPkgs.length;

      PackageService.getUnshipped(this.sourceFilterOptions).then((response) => {
        if (Array.isArray(response.content)) {
          this.unshippedPackages = response.content;
          this.allSourcePackages = this.unselectedShipmentPackages.concat(response.content || []);
          this.totalSourcePackages = response.totalElements - this.allTargetPackages.length;
        } else {
          this.unshippedPackages = [];
          this.totalSourcePackages = 0;
        }
        this.timeoutPackages = setTimeout(this.loadPackages, this.timeout);
      });
    },
    reloadShipment() {
      if (this.timeoutShipment) {
        clearTimeout(this.timeoutShipment);
      }
      this.loadShipment();
    },
    reloadPackages() {
      if (this.timeoutPackages) {
        clearTimeout(this.timeoutPackages);
      }
      this.loadPackages();
    },
    onSourcePaging(event) {
      this.sourceFilterOptions.page = event.page;
      this.sourceFilterOptions.size = event.rows;
      this.reloadPackages();
    },
    onSourceFilter(event) {
      let filter = serializeFilters(event);
      if (this.sourceFilterOptions.filter != filter) {
        this.sourceFilterOptions.filter = filter;
        this.reloadPackages();
      }
    },
    onSourceSort(event) {
      let sorts = serializeOrders(event);
      if (this.sourceFilterOptions.sorts != sorts) {
        this.sourceFilterOptions.sorts = sorts;
        this.reloadPackages();
      }
    },
    tokg(lbs) {
      return converter.tokg(lbs);
    },
    formatDate(jsDate) {
      return formatter.formatDate(jsDate, Consts.FORMAT_DATE_US);
    },
    onChangeAgentCode(event) {
      ShipmentService.getNextShipmentCode(event.value).then(response => {
        this.entity.shipmentCode = response.data;
      })
    },
    _calculateWeights() {
      // this.entity.weightLbs = this.allTargetPackages.reduce(
      //   (prev, cur) => prev + cur.weightLbs,
      //   0
      // );
      // this.entity.weightKg = converter.tokg(this.entity.weightLbs);
    },
    onNew() {
      this.$router.push({ name: 'agent_shipment_create' });
    },
    async onSave() {
      //this.shipment.packageIds = this.allTargetPackages.map((pkg) => pkg.id);
      if (this.entity.referenceCode) {
        this.entity.referenceCode = this.entity.referenceCode.toUpperCase();
      }
      if (!this.submitted) {
        this.errors = this.validate(this.entity, 'shipment');
        if (Object.keys(this.errors).length == 0) {
          this.submitted = true;
          this.showProgressSpinner = true;
          let updatedEntity = this.entity;
          try {
            if (this.entity.id) {
              updatedEntity = await ShipmentService.update(this.entity.id, this.entity);
              // this.allSourcePackages.forEach(item=>{item.shipmentCode = null;});
              // this.allTargetPackages.forEach(item=>{item.shipmentCode = this.shipment.shipmentCode;});
              this.$toast.add({ severity: 'success', summary: 'Successful', detail: this.$t("shipment.update_packages_successfully"), life: 3000 });
            } else {
              updatedEntity = await ShipmentService.create(this.entity);
            }
            this.showProgressSpinner = false;
            this.entity = adjustment.adjustShipment(updatedEntity);
            this.$router.push({ name: 'agent_shipment_list'});
          } catch (e) {
            console.log(e);
            this.showProgressSpinner = false;
          }
        }
      }
    },
    onPreviewShipping() {
      const routeData = this.$router.resolve({ name: 'print_preview', params: {resource: "shipment", id: this.shipment.id} });
      window.open(routeData.href);
    },
    onEditInfo() {
      this.showDialogInfo = true;
    },
    openScanner() {
      const routeData = this.$router.resolve({ name: 'agent_package_scanner', params: {id: this.shipment.id} });
      window.open(routeData.href);
    },
    showShipmentURL() {
      this.showDialogQRCode = true;
    },
    saveEntityInfo(info) {
      for (let field in info){
        this.shipment[field] = info[field];
      }
      if (this.shipment.id) {
        let data = {
          id: this.shipment.id
        };
        if (this.shipment.status == "inited") {
          ["note","departure","awbCode","weightLbs"].forEach(field => {
            data[field] = this.shipment[field];
          });
        } else {
          data.note = this.shipment.note;
        }
        ShipmentService.update(this.shipment.id, data).then(
          (response) => {
            this.shipment = adjustment.adjustShipment(response);
            this.showDialogInfo = false;
          }
        );
      } else {
        ShipmentService.create(this.shipment)
          .then((response) => {
            this.shipment = adjustment.adjustShipment(response);
            this.showDialogInfo = false;
          })
          .catch((e) => console.log(e));
      }
      
    },
    onMoveToTarget() {
      this._moveFromSourceToTarget(this.selectedSourcePackages);
    },
    onMoveAllToTarget() {
      this._moveFromSourceToTarget(this.allSourcePackages);
    },
    onMoveToSource() {
      this._moveFromTargetToSource(this.selectedTargetPackages);
    },
    onMoveAllToSource() {
      this._moveFromTargetToSource(this.allTargetPackages);
    },
    _moveFromSourceToTarget(items) {
      //this.showProgressSpinner = true;
      ShipmentService.addPackages(this.shipment.id, items.map(item=>item.id)).then(
          () => {
            this.reloadPackages();
            this.$toast.add({severity:'success', summary: 'Successful', detail: this.$t("shipment.update_packages_successfully"), life: 3000});
          }
        ).catch((e) => {
            console.log(e);
        }).finally(()=>{
          this.reloadShipment()
          this.showProgressSpinner = false;
        });
      // this.allTargetPackages.push(...items);
      // this.allSourcePackages = this.allSourcePackages.filter(
      //   (item) => !items.includes(item)
      // );      
      // this.selectedSourcePackages = [];
      // let itemIds = {};
      // items.forEach(item => {
      //   if (item.shipmentCode) {
      //     itemIds[item.id] = true;
      //   }        
      // });
      // let newArrs = [];
      // this.unselectedShipmentPackages.forEach(item => {
      //   if (itemIds[item.id] == undefined) {
      //     newArrs.push(item);
      //   }
      // });
      // this.unselectedShipmentPackages = newArrs;
      // this._calculateWeights();
      // this.loadUnshippedPackages();
    },
    _moveFromTargetToSource(items) {
      //this.showProgressSpinner = true;
      ShipmentService.removePackages(this.shipment.id, items.map(item=>item.id)).then(
          () => {
            this.reloadPackages();
            this.$toast.add({severity:'success', summary: 'Successful', detail: this.$t("shipment.update_packages_successfully"), life: 3000});
          }
        ).catch((e) => {
            console.log(e);
        }).finally(()=>{
          this.reloadPackages()
          this.showProgressSpinner = false;
        });
      // this.allTargetPackages = this.allTargetPackages.filter(
      //   (item) => !items.includes(item)
      // );      
      // this.selectedTargetPackages = [];
      // items.forEach(item => {
      //   if (item.shipmentCode) {
      //     this.unselectedShipmentPackages.push(item);
      //     this.allSourcePackages.unshift(item);
      //   } else {
      //     this.allSourcePackages.push(item);
      //   }
      // });
      // this._calculateWeights();
    },
    onSourcePage(event) {
      console.log(event);
    },
    onTargetPage(event) {
      console.log(event);
    },
    onClickApply(event) {
      console.log(event);
    },
    onExportManifest() {
      let receipts = {};
      let selectedPackages = this.allPackages[1];
      selectedPackages = selectedPackages.sort(
        (a, b) => a.packageCode < b.packageCode
      );
      this.shipment.receipts.forEach((receipt) => {
        receipts[receipt.receiptCode] = receipt;
      });
      /*
      selectedPackages.forEach(pkg => {
        const receipt = this._addPackage(receipts[pkg.receiptCode], pkg)
        if (receipt) {
          receipts[pkg.receiptCode] = receipt
        }
      });
      */
      //Manifests
      const manifests = this._createManifests(selectedPackages, receipts);
      // Shipping form
      const shipingForm = this._createShippingForm(selectedPackages, receipts);

      var wb = utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      utils.book_append_sheet(wb, manifests, "Manifests"); // sheetAName is name of Worksheet
      utils.book_append_sheet(wb, shipingForm, "ShippingList"); // sheetAName is name of Worksheet
      // export Excel file
      const fileName = "shipment-" + this.shipment.shipmentCode + ".xlsx";
      writeFile(wb, fileName); // name of the file is 'book.xlsx'
    },
    _createManifests(selectedPackages, mapReceipts) {
      const manifests = selectedPackages.map((pkg, ind) => {
        const receipt = mapReceipts[pkg.receiptCode];
        const hdrPackageCode = this.$t("package.packageCode");
        const hdrWeightLbs = this.$t("shipment.weightLbs");
        const hdrWeightKg = this.$t("shipment.weightKg");
        const hdrSenderName = this.$t("shipment.senderName");
        const hdrSenderAddress = this.$t("shipment.senderAddress");
        const hdrSenderPhone = this.$t("shipment.senderPhone");
        const hdrRecipientName = this.$t("shipment.recipientName");
        const hdrRecipientAddress = this.$t("shipment.recipientAddress");
        const hdrRecipientPhone = this.$t("shipment.recipientPhone");
        const hdrDescription = this.$t("shipment.description");
        return {
          No: ind + 1,
          [hdrPackageCode]: pkg.packageCode,
          ["Pcs"]: 1,
          [hdrWeightLbs]: receipt.weightLbs,
          [hdrWeightKg]: converter.tokg(receipt.weightLbs),
          [hdrSenderName]: receipt.dSenderName || receipt.senderName,
          [hdrSenderAddress]: receipt.dSenderAddress || receipt.senderAddress,
          [hdrSenderPhone]: receipt.dSenderPhone || receipt.senderPhone,
          [hdrRecipientName]: receipt.dRecipientName || receipt.recipientName,
          [hdrRecipientAddress]:
            receipt.dRecipientAddress || receipt.recipientAddress,
          [hdrRecipientPhone]:
            receipt.dRecipientPhone || receipt.recipientPhone,
          
          ["Value"]: "GIFT",
          [hdrDescription]: pkg.packageItems
            .map((item) => item.quantity + " " + item.name)
            .join(", "),
        };
      });
      var sheet = utils.json_to_sheet(manifests, {
        origin: "A4",
      });
      sheet["!cols"] = [
        { wch: 6 },
        { wch: 12 },
        { wch: 18 },
        { wch: 30 },
        { wch: 10 },
        { wch: 18 },
        { wch: 30 },
        { wch: 10 },
        { wch: 6 },
        { wch: 6 },
        { wch: 6 },
        { wch: 30 },
      ];

      const totalWeight = selectedPackages.reduce(
        (total, pkg) => total + pkg.weightLbs,
        0
      );
      utils.sheet_add_aoa(sheet, [[this.shipment.shipmentCode]], {
        origin: "A1",
      });
      utils.sheet_add_aoa(sheet, [[this.shipment.awbCode]], {
        origin: "C1",
      });
      utils.sheet_add_aoa(
        sheet,
        [
          [
            this.$t("shipment.number_of_packages", {
              numPackages: selectedPackages.length,
            }),
          ],
        ],
        { origin: "A2" }
      );
      utils.sheet_add_aoa(
        sheet,
        [[this.$t("shipment.totalWeight", { weight: totalWeight })]],
        { origin: "D2" }
      );
      return sheet;
    },
    _createShippingForm(selectedPackages, mapReceipts) {
      const hdrIndex = this.$t("package.noOfPCs");
      const hdrReceiptNumber = this.$t("shipment.receiptNumber");
      const hdrPackingNumber = this.$t("shipment.packingNumber");
      const hdrShipmentNumber = this.$t("shipment.shipmentNumber");
      const hdrWeightLbs = this.$t("shipment.weightLbs");
      const hdrWeightKg = this.$t("shipment.weightKg");
      const hdrRecipientName = this.$t("shipment.recipientName");
      const hdrRecipientAddress = this.$t("shipment.recipientAddress");
      //const hdrRecipientDistrict = this.$t('shipment.recipientDistrict');
      //const hdrRecipientCity = this.$t('shipment.recipientCity');
      const hdrRecipientPhone = this.$t("shipment.recipientPhone");
      const hdrDescription = this.$t("shipment.description");
      const shippingForm = selectedPackages.map((pkg, ind) => {
        const receipt = mapReceipts[pkg.receiptCode];
        return {
          [hdrIndex]: ind + 1,
          [hdrReceiptNumber]: pkg.receiptCode,
          [hdrPackingNumber]: pkg.packageCode,
          [hdrShipmentNumber]: this.shipment.shipmentCode,
          [hdrWeightLbs]: pkg.weightLbs,
          [hdrWeightKg]: converter.tokg(pkg.weightLbs),
          [hdrRecipientName]: receipt.dRecipientName || receipt.recipientName,
          [hdrRecipientAddress]:
            receipt.dRecipientAddress ||
            [
              receipt.recipientAddress,
              receipt.receiptDistrict,
              receipt.receiptProvince,
            ].join(","),
          [hdrRecipientPhone]:
            receipt.dRecipientPhone || receipt.recipientPhone,
          [hdrDescription]: pkg.packageItems
            .map((item) => item.quantity + " " + item.name)
            .join(","),
        };
      });
      var sheet = utils.json_to_sheet(shippingForm, {
        origin: "A4",
      });
      sheet["!cols"] = [
        { wch: 6 },
        { wch: 10 },
        { wch: 12 },
        { wch: 8 },
        { wch: 6 },
        { wch: 6 },
        { wch: 18 },
        { wch: 30 },
        { wch: 10 },
        { wch: 30 },
      ];
      const totalWeight = selectedPackages.reduce(
        (total, pkg) => total + pkg.weightLbs,
        0
      );
      utils.sheet_add_aoa(sheet, [[this.$t("shipment.shippingFormTitle")]], {
        origin: "A1",
      });
      utils.sheet_add_aoa(
        sheet,
        [
          [
            this.$t("shipment.number_of_packages", {
              numPackages: selectedPackages.length,
            }),
          ],
        ],
        { origin: "A2" }
      );
      utils.sheet_add_aoa(
        sheet,
        [[this.$t("shipment.totalWeight", { weight: totalWeight })]],
        { origin: "D2" }
      );
      return sheet;
    },
    _addPackage(receipt, pkg) {
      if (!receipt && this.shipment && this.shipment.receipts) {
        for (let i = 0; i < this.shipment.receipts.length; i++) {
          if (this.shipment.receipts[i].receiptCode == pkg.receiptCode) {
            receipt = {
              ...this.shipment.receipts[i],
              packageNumber: 0,
              weightLbs: 0,
              content: "",
            };
            break;
          }
        }
      }
      if (receipt) {
        receipt.packageNumber += 1;
        receipt.weightLbs += pkg.weightLbs;
        if (!receipt.content) {
          receipt.content = this._getPackageContent(pkg);
        } else {
          receipt.content += "," + this._getPackageContent(pkg);
        }
      }
      return receipt;
    },
    //Todo:
    _getPackageContent(pkg) {
      return pkg.description;
    },
  },
};
</script>

<style lang="scss" scoped>
/*
.p-picklist-header .p-toolbar {
  padding: 0 1rem;
  margin: -0.5rem 0;
  border: 0;
  font-weight: 600;
}
*/
</style>
